import React from "react"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import Subscribe from "../components/investors/Subscribe"
import NewsClientNew from "../components/NewsClientNew"
import PageHeader from "../components/PageHeader"
import Seo from "../components/seo"
export default function NewsPage() {
  return (
    <Layout>
      <div className="py-16 md:py-24 px-4">
        <Seo title="News" />
        <PageHeader text="News" />
        <Subscribe />
        <div className="block">
          <NewsClientNew />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
